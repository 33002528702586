<template>
  <mobile-screen :header="true" screen-class="resources-screen gray-bg">
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="resource-header icon-hea1">
        <template v-slot:left>
          <router-link
            :to="{
              name: backLinkName,
            }"
          >
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title">
          {{
            displayLabelName("notifications.notifications.edit-notification")
          }}
        </div>
        <template v-slot:right>
          <button
            @click="initSubmit"
            v-if="
              $route.name == 'r_edit-company-notification' &&
                can('MANAGE_NOTIFICATIONS', 'update')
            "
          >
            <icon icon="#cx-hea1-save" />
          </button>
        </template>
      </top-header-menu-wrapper>
    </template>
    <VeeForm
      :key="formKey"
      ref="editCompanyNotificationForm"
      @submit="submitForm"
      v-slot="{ errors }"
      class="form inline-input edit-form"
      novalidate
    >
      <ul class="clebex-item-section">
        <li
          class="clebex-item-section-item full-right-underline"
          :class="{ 'has-error': errors.has_translations }"
        >
          <div class="error-message" v-if="errors.has_translations">
            {{ errors.has_translations }}
          </div>
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <div class="clebex-section-input-label">
                {{ displayLabelName("notifications.notifications.title") }}
              </div>
              <p class="text-label">{{ displayCompanyNotificationName }}</p>
            </div>
            <span class="follow-up-icons">
              <router-link
                :to="{
                  name: 'r_edit-company-notification-translate-name',
                  params: $route.params,
                }"
                class="follow-up-icon-item"
              >
                <icon icon="#cx-men1-translations" />
              </router-link>
            </span>
          </div>
          <Field name="has_translations" as="input" type="hidden" />
        </li>

        <li
          class="clebex-item-section-item full-right-underline"
          :class="{ 'has-error': errors.has_translations }"
        >
          <div class="error-message" v-if="errors.has_translations">
            {{ errors.has_translations }}
          </div>
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <div class="clebex-section-input-label">
                {{ displayLabelName("notifications.notifications.body") }}
              </div>
              <p class="text-label">
                {{ displayCompanyNotificationDescription }}
              </p>
            </div>
            <span class="follow-up-icons">
              <router-link
                :to="{
                  name: 'r_edit-company-notification-translate-description',
                  params: $route.params,
                }"
                class="follow-up-icon-item"
              >
                <icon icon="#cx-men1-translations" />
              </router-link>
            </span>
          </div>
          <Field name="has_translations" as="input" type="hidden" />
        </li>
      </ul>
      <ul class="clebex-item-section">
        <li
          class="clebex-item-section-item full-right-underline"
          :class="{ 'has-error': errors.level }"
          style="cursor: pointer;"
        >
          <router-link
            :to="{
              name: 'r_edit-company-notification-send-to',
              params: $route.params,
            }"
            class="clebex-item-content-wrapper"
          >
            <div class="clebex-section-input" style="cursor: pointer;">
              <label
                class="clebex-section-input-label"
                style="cursor: pointer;"
              >
                {{ displayLabelName("notifications.notifications.send-to") }}
              </label>
              <p
                v-if="sendTo"
                class="text-label"
                style="cursor: pointer;"
                v-html="sendTo"
              ></p>
            </div>
            <span class="follow-up-icons" style="cursor: pointer;">
              <span class="follow-up-icon-item" style="cursor: pointer;">
                <icon
                  icon="#cx-app1-arrow-right-12x12"
                  width="12"
                  height="12"
                  style="cursor: pointer;"
                />
              </span>
            </span>
          </router-link>
        </li>
        <li
          class="clebex-item-section-item full-right-underline"
          :class="{ 'has-error': errors.level }"
          style="cursor: pointer;"
        >
          <router-link
            :to="{
              name: 'r_edit-company-notification-send-at',
            }"
            class="clebex-item-content-wrapper"
          >
            <div class="clebex-section-input" style="cursor: pointer;">
              <label
                class="clebex-section-input-label"
                style="cursor: pointer;"
              >
                {{ displayLabelName("notifications.notifications.send-at") }}
              </label>
              <p
                v-if="sendAt"
                class="text-label"
                style="cursor: pointer;"
                v-html="sendAt"
              ></p>
            </div>
            <span class="follow-up-icons" style="cursor: pointer;">
              <span class="follow-up-icon-item" style="cursor: pointer;">
                <icon
                  icon="#cx-app1-arrow-right-12x12"
                  width="12"
                  height="12"
                  style="cursor: pointer;"
                />
              </span>
            </span>
          </router-link>
        </li>
      </ul>
      <div class="clebex-item-section">
        <clebex-button
          type="button"
          :label="displayLabelName('notifications.notifications.send-now')"
          @click="showSendNowDialog = true"
        >
        </clebex-button>
      </div>
      <button type="submit" style="display: none;" ref="submitFormBtn">
        Submit form
      </button>
    </VeeForm>
    <template v-slot:footer>
      <nav class="actions-menu theme-gray">
        <ul class="actions-list">
          <li class="action"></li>
          <li class="action">
            <button class="action-btn" @click="showSendTrialDialog = true">
              {{ displayLabelName("global.footer.send-trial") }}
            </button>
          </li>
        </ul>
      </nav>
    </template>
    <screen-modal
      v-if="showSendNowDialog"
      class="confirm-modal"
      type="success"
      :confirm-action="sendNow"
      :confirm-button-label="displayLabelName('global.buttons.ok')"
      :cancelButtonLabel="displayLabelName('global.buttons.cancel')"
      :show="showSendNowDialog"
      @close="showSendNowDialog = false"
    >
      <h3 class="modal-title">
        {{ displayLabelName("notifications.notifications.send-now-title") }}
      </h3>
      <br />
      <p class="text">
        {{
          displayLabelName("notifications.notifications.send-now-action-text")
        }}
        <br />
        {{ displayLabelName("global.messages.delete-action-question") }}
      </p>
    </screen-modal>
    <screen-modal
      v-if="showSendTrialDialog"
      class="confirm-modal"
      type="success"
      :confirm-action="sendTrial"
      :confirm-button-label="displayLabelName('global.buttons.ok')"
      :cancelButtonLabel="displayLabelName('global.buttons.cancel')"
      :show="showSendTrialDialog"
      @close="showSendTrialDialog = false"
    >
      <h3 class="modal-title">
        {{ displayLabelName("notifications.notifications.send-trial-title") }}
      </h3>
      <br />
      <p class="text">
        {{
          displayLabelName("notifications.notifications.send-trial-action-text")
        }}
        <br />
        {{ displayLabelName("global.messages.delete-action-question") }}
      </p>
    </screen-modal>
  </mobile-screen>
  <router-view />
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapActions, mapState } from "vuex";
import httpServiceAuth, { getLang } from "@/services/http-service";
import { apiEndpoints } from "@/services/constants";
import { errorHandler } from "@/services/error-handler";
import ClebexButton from "@/components/global/Button";

export default {
  name: "AddCompanyNotification",
  data() {
    return {
      formKey: 0,
      showSendNowDialog: false,
      showSendTrialDialog: false,
    };
  },
  created() {
    this.getCompanyNotification(this.$route.params.company_notification_id);
  },
  computed: {
    ...mapState("companyNotifications", [
      "companyNotificationTranslations",
      "datetimeFrom",
      "datetimeTo",
      "day",
      "users",
      "allUsers",
      "userContainers",
      "sendTo",
      "sendAt",
    ]),
    ...mapState("language", ["languages"]),
    routeParam() {
      return this.$route.params.company_notification_id;
    },
    hasNameTranslations() {
      const { languages, companyNotificationTranslations } = this;
      if (languages && languages.data && languages.data.length) {
        return languages.data.length === companyNotificationTranslations.length;
      }
      return false;
    },
    hasDescriptionTranslations() {
      const { languages, companyNotificationTranslations } = this;
      if (languages && languages.data && languages.data.length) {
        return languages.data.length === companyNotificationTranslations.length;
      }
      return false;
    },
    displayCompanyNotificationName() {
      const { companyNotificationTranslations } = this;
      const locale = getLang();
      if (
        companyNotificationTranslations &&
        companyNotificationTranslations.length
      ) {
        const filtered = companyNotificationTranslations.filter(
          (item) => item.locale === locale
        );
        if (filtered && filtered.length) {
          return filtered[0].attributes.name;
        } else {
          return "";
        }
      } else {
        return "";
      }
    },
    displayCompanyNotificationDescription() {
      const { companyNotificationTranslations } = this;
      const locale = getLang();
      if (
        companyNotificationTranslations &&
        companyNotificationTranslations.length
      ) {
        const filtered = companyNotificationTranslations.filter(
          (item) => item.locale === locale
        );
        if (filtered && filtered.length) {
          return filtered[0].attributes.description;
        } else {
          return "";
        }
      } else {
        return "";
      }
    },
  },
  watch: {
    languages() {
      this.setTranslations();
    },
    routeParam() {
      if (this.routeParam) {
        this.getCompanyNotification(this.$route.params.company_notification_id);
      }
    },
  },
  methods: {
    ...mapActions("companyNotifications", [
      "getCompanyNotifications",
      "getCompanyNotification",
      "setCompanyNotificationTranslations",
    ]),
    submitForm(values) {
      if (values) {
        this.submitCompanyData(values);
      }
    },
    setTranslations() {
      if (this.languages && this.languages.data) {
        let translations = [];
        this.languages.data.forEach((language) => {
          let object = {
            locale: language.locale,
            attributes: {
              name: "",
              description: "",
            },
          };
          translations.push(object);
        });
        this.setCompanyNotificationTranslations(translations);
      }
    },
    sortLanguages(a, b) {
      const currentLang = getLang();
      if (a.locale === currentLang && b.locale !== currentLang) {
        return -1;
      }

      if (a.locale !== currentLang && b.locale === currentLang) {
        return 1;
      }

      return 0;
    },
    submitCompanyData(values) {
      this.$store.commit("loader/setScreenLoading", true, { root: true });

      if (this.companyNotificationTranslations.length) {
        values.translations = this.companyNotificationTranslations;
      }

      values.datetime_from = this.datetimeFrom;
      values.datetime_to = this.datetimeTo;
      values.all_users = this.allUsers;
      values.user_containers = this.userContainers;
      values.users = this.users;
      values.day = this.day;

      httpServiceAuth
        .put(
          `${apiEndpoints.company.companyNotifications}/${this.$route.params.company_notification_id}`,
          values
        )
        .then((response) => {
          this.getCompanyNotification(
            this.$route.params.company_notification_id
          );
          this.getCompanyNotifications();
          this.$store.commit(
            "companyNotifications/setCompanyNotificationTranslations",
            [],
            {
              root: true,
            }
          );
          setTimeout(() => {
            if (this.formKey === 10) {
              this.formKey = 0;
            } else {
              this.formKey++;
            }
          }, 0);
          this.$router.push({
            name: "r_edit-company-notification",
            params: { company_notification_id: response.data.data.id },
          });
        })
        .catch((error) => {
          if (error.response) {
            errorHandler(error.response, this.findElement());
          }
        })
        .finally(() => {
          this.$store.commit("loader/setScreenLoading", false, { root: true });
        });
    },
    sendNow() {
      this.$store.commit("loader/setScreenLoading", true, { root: true });
      httpServiceAuth
        .post(`${apiEndpoints.company.companyNotifications}/sendnow`, {
          notification_id: this.$route.params.company_notification_id,
        })
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          if (error.response) {
            errorHandler(error.response, this.findElement());
          }
        })
        .finally(() => {
          this.$store.commit("loader/setScreenLoading", false, { root: true });
        });
    },
    sendTrial() {
      this.$store.commit("loader/setScreenLoading", true, { root: true });
      httpServiceAuth
        .post(`${apiEndpoints.company.companyNotifications}/sendtrial`, {
          notification_id: this.$route.params.company_notification_id,
        })
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          if (error.response) {
            errorHandler(error.response, this.findElement());
          }
        })
        .finally(() => {
          this.$store.commit("loader/setScreenLoading", false, { root: true });
        });
    },
    initSubmit() {
      const { submitFormBtn } = this.$refs;
      submitFormBtn.click();
    },
  },
  beforeUnmount() {
    this.$store.commit(
      "companyNotifications/setCompanyNotificationTranslations",
      [],
      {
        root: true,
      }
    );
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper,
    ClebexButton,
  },
  props: {
    backLinkName: {
      type: String,
      required: true,
    },
  },
};
</script>
